.app {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
}

.app__main {
    flex-grow: 1;
    position: relative;
    pointer-events: none;

    > * {
        pointer-events: all;
    }
}
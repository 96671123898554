
.archive--col-0 {
  @include archive-col;
  max-width: 50%;

  @media(max-width: 767px) {
  	max-width: 90%;
  }
}

.archive--col-0.archive-header-button {
  @media(max-width: 767px) {
  	max-width: 100%;
  	justify-content: space-between;
  }
}

.archive--col-1,
.archive--col-1.archive-header-button {
  @include archive-col;
	max-width: 25%;

  @media(max-width: 767px) {
  	display: none;
  }
}

.archive--col-2 {
  @include archive-col;
  max-width: 25%;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 767px) {
  	max-width: 10%;
  	justify-content: flex-end;
  }
}

.archive-header-button.archive--col-2 {
  @media(max-width: 767px) {
		display: none;
	}
}

.archive--col-2 p {
  @media(max-width: 767px) {
  	display: none;
  }
}

.archive--col-2 .archive-header-button {
  @media(max-width: 767px) {
    display: none;
  }
}

.archive--col-2 p {
  @media(max-width: 767px) {
    display: none;
  }
}

.about {
	margin: 8rem 0;
	@media(max-width: 767px) {
		margin: 2rem 0;
	}
	@media(max-width: 499px) {
		margin: 0;
	}
}

.about__section {
	width: 90%;
	max-width: 45rem;
	margin-left: auto;
	margin-right: auto;
}

.about__section h2 {
	@include typo--h2;
	margin-top: 2em;
}

.about__section p {
	@include typo--body;
	margin-bottom: 1em;
}

.about__section li {
	@include typo--body;
}

.about__section ul:not(.metadata-table) {
	margin-bottom: 1em;
	margin-left: 2em;
	list-style: disc;
}

.about__section:first-of-type h2:first-child {
	margin-bottom: 1em;
}

.about-section__main-logo {
	display: block;
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
	@media(max-width: 499px) {
		margin-top: 40px;
		width: 60%;
	}
}

.about__supporter {
	vertical-align: middle;
	margin-bottom: 1rem;

	@media (min-width: 769px) {
		margin-right: 2rem;
		width: calc(16% - 1.4rem);
	
		&:nth-child(6n) {
			margin-right: 0;
		}
	}

	@media (max-width: 768px) {
		margin-right: 1rem;
		width: calc(33% - 0.7rem);
	
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}
.archive-filters {
  	width: 90%;
	max-width: 40rem;
	margin: 8rem 0;
	margin-left: auto;
	margin-right: auto;
}

.archive-filters__section {
	margin-bottom: 2.5rem;
}

.archive-filters__subtitle {
	@include typo--metadata-title;
	margin-bottom: 1em;
}

.archive-filters__toggle {
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	height: auto;
	min-height: 1.8em;
}
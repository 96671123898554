.overlay-layout {
    width: 100%;
    height: 100%;
    height: calc(100% - #{$size--header-height});
    top: $size--header-height;
    position: absolute;
    padding: 0 $padding--large;
    transform: translateY(105%);
    transition: transform 0.3s ease;
    box-sizing: border-box;
}

.overlay-layout--is-open {
    transform: translateY(0);
    pointer-events: all;
}

.overlay-layout__inner {
    background: $color--background;
    width: calc(100% - #{$padding--large * 2});
    height: 100%;
    /*width: 100%;*/
    position: absolute;
    border: $appearance--border;
    overflow: hidden;

    @media(max-width: 499px) {
        max-height: 100vh;
    }
}

.overlay-layout__content {
    height: 100%;
    max-height: calc(100vh - #{$size--header-height});
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    width: 100%;
    background-color: $color--primary;
}

.overlay-layout--is-open .overlay-layout__content {
    pointer-events: all;
}

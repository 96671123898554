.player-controls {
  @include player-controls;
}

.player-controls--is-hidden {
  pointer-events: none;
  opacity: 0;
}

.player-controls__inner {
  @include player-controls__inner;
}

.button-holder--play-pause {
  width: auto;
  display: inline-block;
}

.player-controls__info-container {
  @include player-controls__info-container
}

.player-controls__info-container--additional-controls-visible-on-mobile {
  @media(max-width: 767px) {
    width: calc(100% - (#{$padding--med} * 2));
    left: $padding--med;
    flex-wrap: wrap-reverse;

    @media(min-width: 340px) {
      justify-content: flex-end;
    }
  }
}

.player-controls__toggle-container .core-button {
  margin-left: 0.3rem;
}

.player-controls__play-pause-button {
  @include player-controls__play-pause-button;
}

.player-controls__toggle-additional-controls {
  width: 2em;
  margin-bottom: 0.2em;
  @media(min-width: 768px) {
    display: none;
  }
}

.player-controls__additional-control {
  margin-right: 0.2em;
  margin-bottom: 0.2em;
}

.player-controls__additional-control--hidden-on-mobile {
  @media(max-width: 767px) {
    display: none;
  }
}

.player-controls__title {
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  margin-top: -0.13em;
  @include typo--body;
  @media(max-width: 319px) {
    display: none;
  }
}

.large-text .player-controls__title {
  @media(max-width: 499px) {
    display: none;
  }
}

.player-controls__title--hidden-on-mobile {
  @media(max-width: 767px) {
    display: none;
  }
}

.chapter-markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chapter-markers__marker {
  width: 4px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  opacity: 0.5;
}

.progress-bar__outer:focus .chapter-markers__marker {
  opacity: 1;
}

.hide-below-768 {
  @media(max-width: 767px) {
    display: none;
  }
}

.chapter-markers__marker:nth-child(1) {
  left: 6.9%;
}

.chapter-markers__marker:nth-child(2) {
  left: 12.6%;
}

.chapter-markers__marker:nth-child(3) {
  left: 17.8%;
}

.chapter-markers__marker:nth-child(4) {
  left: 27.5%;
}

.chapter-markers__marker:nth-child(5) {
  left: 32%;
}

.chapter-markers__marker:nth-child(6) {
  left: 40.2%;
}

.chapter-markers__marker:nth-child(7) {
  left: 48.1%;
}

.chapter-markers__marker:nth-child(8) {
  left: 51.45%;
}

.chapter-markers__marker:nth-child(9) {
  left: 59.7%;
}

.chapter-markers__marker:nth-child(10) {
  left: 62.4%;
}

.chapter-markers__marker:nth-child(11) {
  left: 75.4%;
}

.chapter-markers__marker:nth-child(12) {
  left: 85.4%;
}


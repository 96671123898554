.metadata-table {
  margin: 4rem 0;
}

.metadata-table__row:not(:last-child) {
  margin-bottom: 2rem;
  @media(min-width: 500px) {
    margin-bottom: 0.625rem;
  }
}

.metadata-table__label {
  @include typo--metadata-title;
  display: inline-block;
  padding-right: 1em;
  vertical-align: middle;
  width: 100%;
  @media(min-width: 500px) {
    width: 40%;
  }
}

.metadata-table__value {
  @include typo--body-small;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  @media(min-width: 500px) {
    width: 60%;
  }
}

.metadata-table__row--mobile-only {
  @media(min-width: 500px) {
    display: none;
  }
}

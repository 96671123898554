.transcript {
	margin: 8rem 0;
}

.transcript p {
	@include typo--body;
	width: 90%;
	max-width: 50rem;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
}

.transcript em {
	font-style: italic;
}

.transcript strong {
	@include typo--transcript-label;
}
.play-pause-button {
    border-radius: 50%;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    cursor: pointer;
    background: #fff;
    border: 2px solid #fff;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    user-select: none;

    &:before {
        content: '';
        width: 200%;
        height: 200%;
        background: red;
        position: absolute;
        opacity: 0;
    }
}

.play-pause-button:active {
    background: $color--primary;
    border-color: #fff;
}

.play-pause-button svg {
    width: 40%;
    height: auto;
}

.play-pause-button--is-paused svg {
    margin-left: 7%;
}

.play-pause-button svg * {
    fill: $color--primary;
}

.play-pause-button:active svg * {
    fill: #fff;
}

.player-controls__play-pause-button--hidden-on-mobile {
  @media(max-width: 767px) {
    display: none;
  }
}

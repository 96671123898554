.archive-header {
  display: flex;
  position: absolute;
  top: 0;
  background-color: $color--background;
  // top: calc(#{$size--header-height} + #{$width--border});
  width: 100%;
  height: $height--archive-header;
  justify-content: space-between;
  width: 100%;
  padding-left: $padding--small;
  padding-right: $padding--small;
  z-index: 998;
  border-bottom: 2px solid #000;
}


.archive__list {
	margin-left: $padding--small;
	margin-right: $padding--small;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	padding-top: $height--archive-header;
	padding-bottom: 100px;
}

.archive__no-items {
	display: block;
	margin-top: $height--archive-header * 1.5;
	margin-left: $padding--small;
	cursor: pointer;
	@include typo--body-small;
}
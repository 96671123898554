@mixin archive-col {
	position: relative;
	width: 100%;
	display: flex;
  align-items: center;
}

@mixin heading-font-style {
	color: $color--accent;
	font-family: $font-family--headings;
	text-transform: uppercase;
}

@mixin intro-font-appearance {
	font-size: 2rem;
	color: $color--accent;
	font-family: $font-family--headings;
}

@mixin archive-content-margins {
  @media(min-width: 500px) {
    margin-left: 48px;
    margin-right: 48px;
  }
}

@mixin player-controls {
  height: auto;
  width: 100%;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
  bottom: 0;
  left: 0;
  padding: $padding--large;
  @media(max-width: 499px) {
    padding: 0;
  }
}

@mixin player-controls__inner {
  position: relative;
  width: 100%;
  height: 70px;
  background: $color--primary;
  pointer-events: all;
  justify-content: space-between;
}

@mixin player-controls__info-container {
  width: calc(100% - (#{$diameter--button--play-pause__large} + (#{$padding--med} * 3)));
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  left: calc(#{$diameter--button--play-pause__large} + (#{$padding--med} * 2));
  right: 10px;
  top: 10px;
}

@mixin player-controls__play-pause-button {
  width: $diameter--button--play-pause__large;
  height: $diameter--button--play-pause__large;
  margin: 10px;
}

@mixin button--play-pause__large {
  height: $diameter--button--play-pause__large;
  width: $diameter--button--play-pause__large;
  border: 2px solid $color--background;
  background: $color--background;
  border-radius: 50%;
  margin: $padding--med;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  position: absolute;
  bottom: 0;
  left: 0;
}

.archive-item {
  padding-top: $padding--med;
  padding-bottom: $padding--med;
  width: calc(100% - (#{$padding--small} * 2));
  border-bottom: $appearance--border-thin;
}

.archive-item:last-child {
  border-bottom: none;
}

.archive-item__header {
  display: flex;
  align-items: center;
  @include typo--body-small;
}

.archive-item__header-title-container {
  border: 1px solid transparent;
  margin-right: 15px;
  overflow: hidden;
  cursor: pointer;
}

.archive-item__play-pause-icon {
  width: 1.1rem;
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.archive-item__play-pause-icon svg {
  vertical-align: -2px;
}

.archive-item__play-pause-icon svg * {
  fill: $color--primary;
}

.archive-item--is-playing .archive-item__play-pause-icon {
  opacity: 1;
}

@media (hover: hover) { 
  .archive-item__header-title-container--audio-button:hover {
    .archive-item__play-pause-icon {
      opacity: 1;
    }

    .archive-item__header-title {
      transform: translateX(1.3rem);
    }
  }
}

@media (hover: none) { 
  .archive-item__header-title-container--audio-button {
    .archive-item__play-pause-icon {
      opacity: 1;
    }

    .archive-item__header-title {
      transform: translateX(1.3rem);
    }
  }
}

.archive-item__header-title {
  transition: transform 0.2s ease;
  text-overflow: ellipsis;
  overflow: hidden;
}

.archive-item--is-playing .archive-item__header-title {
  transform: translateX(1.3rem);
  font-weight: 700;
  
}

.archive-item__main {
  padding: 2.5rem 0;
  display: none;
}

.archive-item__main--expanded {
  display: block;
}

.archive-item__image-wrapper {
  width: 90%;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: block;
  position: relative;
}

.archive-item__main-img {
  width: 100%;
}

.archive-item__image-link {
  margin-top: 0.5em;
  @include typo--body-small;
}

.archive-item__text-content {
  width: 90%;
	max-width: 50rem;
	margin-left: auto;
	margin-right: auto;
}

.archive-item__description-intro {
  @include typo--h2;
  margin-bottom: 1em;
}

.archive-item__description-main p {
  @include typo--body;
  margin-bottom: 1em;
}

.archive-item__expand-button {
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  border: 2px solid #000;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -75%;
    width: 250%;
    height: 200%;
    background: red;
    opacity: 0;
  }
}

.archive-item__expand-button > span {
  vertical-align: 1px;
  display: inline-block;
  line-height: 1em;
  // transform: translateY(-7%);
}
$color--background: white;

$color--primary: #666699;

$color--red: #77bbbb;

$color--teal: #99cccc;

$color--accent: $color--red;

$color--secondary: $color--teal;

$size--header-height: 60px;

$size--header-height--mobile: 40px;

$size--header-height--mobile--large-text: 50px;

$width--border: 2px;

$width--border-thin: 1px;

$appearance--border: $width--border solid #000;

$appearance--border-thin: $width--border-thin solid #000;

$font-family--headings: 'AlbertusMTPro', optimo, serif;

$font-family--body: 'Work Sans', helvetica, arial, sans-serif;

$padding--small: 5px;

$padding--med: 10px;

$padding--large: 15px;

$line-height--default: 1.6em;

$height--archive-header: 48px;

$height--archive-header--mobile: 36px;

$diameter--button--play-pause__large: 50px;

$diameter--button--play-pause__large--large-text: 60px;

$height--button: 30px;

$height--button--large-text: 36px;

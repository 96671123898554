.header {
  width: 100%;
  padding: $padding--large;
  display: flex;
  position: fixed;
  height: $size--header-height;
  z-index: 999;
  top: 0;
  left: 0;
  @media(max-width: 499px) {
    /*padding: $padding--small;*/
    padding-bottom: 0;
    height: $size--header-height--mobile;
  }
}

.large-text .header {
  @media(max-width: 499px) {
    height: $size--header-height--mobile--large-text;
  }
}

.header__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.large-text .header__button {
  @media(max-width: 499px) {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
}

.search-input {
  @media(max-width: 440px) {
    display: none;
  }
}

.header__title {
  color: $color--primary;
  text-transform: uppercase;
  text-align: center;

  @media(max-width: 499px) {
    display: none;
  }
}

.header__inner {
  @media(max-width: 299px) {
    height: 36px;
  }
}

.text-size-controls {
  white-space: nowrap;
}

.text-size-button {
  text-transform: none;
}

.text-size-button--small {
  font-size: 0.8rem;
}

.text-size-button--large {
  font-size: 1.2rem;
}

.text-size-button:first-child {
  margin-right: 0.2rem;
}

.header__title {
  @include typo--h1;

  @media(max-width: 767px) {
    font-size: 1.2rem;
  }
}

.header__filter-count {
  font-weight: 700;
  color: $color--primary;
}
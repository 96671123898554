@import './vars.scss';
@import './mixins.scss';
@import './typography.scss';

// We import our SCSS here rather than in component files so we can use variables globally
@import '../app.scss';
@import '../layouts/OverlayLayout/overlay-layout.scss';
@import '../layouts/ArchiveColumns/archive-columns.scss';

@import '../components/Core/Button/button.scss';
@import '../components/Core/ButtonUnstyled/button-unstyled.scss';

@import '../components/Header/header.scss';
@import '../views/Archive/archive.scss';
@import '../views/About/about.scss';
@import '../components/ArchiveHeader/archive-header.scss';
@import '../components/ArchiveHeader/archive-header-button.scss';
@import '../components/ArchiveItem/archive-item.scss';
@import '../components/ArchiveAudioPlayer/archive-audio-player.scss';
@import '../components/Transcript/transcript.scss';
@import '../components/SearchInput/search-input.scss';
@import '../components/PlayerControls/player-controls.scss';
@import '../components/PlayPauseButton/play-pause-button.scss';
@import '../components/ProgressBar/progress-bar.scss';
@import '../components/VideoPlayer/video-player.scss';
@import '../components/core/MetadataTable/metadata-table.scss';
@import '../components/ArchiveFilters/archive-filters.scss';

// @import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,700,800&display=swap');


/**
 * @license
 * MyFonts Webfont Build ID 3810941, 2019-09-24T10:59:03-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AlbertusMTPro by Monotype
 * URL: https://www.myfonts.com/fonts/mti/albertus/pro-roman/
 * Copyright: Font software (c) 1992 Adobe Systems Incorporated. Typeface designs (c) 1992 The Monotype Corporation. All rights reserved.
 * Licensed pageviews: 250,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3810941
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3a267d");

  
@font-face {font-family: 'AlbertusMTPro';src: url('/webfonts/3A267D_0_0.eot');src: url('/webfonts/3A267D_0_0.eot?#iefix') format('embedded-opentype'),url('/webfonts/3A267D_0_0.woff2') format('woff2'),url('/webfonts/3A267D_0_0.woff') format('woff'),url('/webfonts/3A267D_0_0.ttf') format('truetype');}
 

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $color--background;
  color: #000;
  font-size: 16px;
}

html.large-text {
  font-size: 20px;
}

* {
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
}

.hide-on-mobile {
  @media(max-width: 767px) {
    display: none;
  }
}

.img-responsive {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

a {
  color: inherit;
}

button:focus,
[role="button"]:focus,
a:focus,
input[type=text]:focus,
[role=progressbar]:focus, {
  outline: 2px dashed $color--teal;
}



.archive-header-button {
  @include typo--metadata-title;
  vertical-align: center;
  height: 100%;
  appearance: none;
  padding: 0;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: relative;

    &:before {
        content: '';
        width: 300%;
        height: 150%;
        background: red;
        position: absolute;
        opacity: 0;
    }
}

.archive-header-button--is-active {
    font-weight: bold;
}

.archive-header-button__direction-icon {
    border: 2px solid #000;
    width: 1rem;
    height: 1rem;
}

.archive-header-button__direction-icon {
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    border: 2px solid #000;
    border-radius: 3px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    margin-left: 0.5em;
    vertical-align: center;
}

.archive-header-button__direction-icon svg {
    transform-origin: 50% 50%;
    width: 50%;
}

.archive-header-button__direction-icon svg * {
    fill: #000;
}

.archive-header-button__direction-icon--down svg {
    transform: rotate(180deg);
}
@mixin typo--h1 {
    font-size: 2rem;
    line-height: 1em;
    font-family:  $font-family--headings;
    color: #fff;
}

@mixin typo--h2 {
    font-size: 1.6rem;
    line-height: 1.4em;
    font-family:  $font-family--headings;
    color: $color--primary;
}

@mixin typo--metadata-title {
    font-size: 1.15rem;
    line-height: 1.125em;
    font-family:  $font-family--headings;
    color: $color--primary;
    text-transform: uppercase;
}

@mixin typo--body {
    font-size: 1.5rem;
    line-height: 1.5em;
    font-family:  $font-family--body;
    letter-spacing: -0.02em;
    font-weight: 500;
}

@mixin typo--body-small {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-family:  $font-family--body;
    letter-spacing: -0.02em;
    font-weight: 500;
}

@mixin typo--button {
    font-size: 1.25rem;
    line-height: 1em;
    text-transform: uppercase;
    font-family:  $font-family--body;
    font-weight: 500;
}

@mixin typo--transcript-label {
    font-family:  $font-family--headings;
    color: $color--primary;
    text-transform: uppercase;
}
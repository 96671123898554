.archive-audio-player__wrapper {
  height: calc(100% - #{$padding--large});
  // overflow: hidden;
  position: relative;
  pointer-events: none;
}

.archive-audio-player {
  @include player-controls;
  transition: -webkit-transform 0.3s ease-in-out, transform 0.3s ease-in-out;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.archive-audio-player.archive-audio-player--is-visible {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.archive-audio-player__inner {
  @include player-controls__inner;
}

.archive-audio-player__info-container {
  @include player-controls__info-container
}

.archive-audio-player__title {
  color: #fff;
  @include typo--body;
  @media(max-width: 499px) {
    display: none;
  }
}

.archive-audio-player__play-pause-button {
  @include player-controls__play-pause-button;
}
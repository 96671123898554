.video-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(12, 12, 12);
    background: #111;
    pointer-events: none;
}

.video-player__video {
    width: 100%;
    height: 100%;
    position: absolute;
    visibility: hidden;
    will-change: visibility;
}

// .video-player__video--default {
//     border: 2px solid green;
// }

// .video-player__video--subtitles {
//     border: 2px solid blue;
// }

.video-player__video--current {
    visibility: visible;
}

.video-player__play-pause-button {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin-top: -60px;
    margin-left: -60px;
    border: 4px solid $color--primary;
}

.video-player__play-pause-button--hidden {
    opacity: 0;
}
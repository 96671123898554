@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,500,700,800&display=swap);
@import url(//hello.myfonts.net/count/3a267d);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input[type="search"] {
	-webkit-appearance: textfield;
}
@charset "UTF-8";
.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute; }

.app__main {
  flex-grow: 1;
  position: relative;
  pointer-events: none; }
  .app__main > * {
    pointer-events: all; }

.overlay-layout {
  width: 100%;
  height: 100%;
  height: calc(100% - 60px);
  top: 60px;
  position: absolute;
  padding: 0 15px;
  transform: translateY(105%);
  transition: transform 0.3s ease;
  box-sizing: border-box; }

.overlay-layout--is-open {
  transform: translateY(0);
  pointer-events: all; }

.overlay-layout__inner {
  background: white;
  width: calc(100% - 30px);
  height: 100%;
  /*width: 100%;*/
  position: absolute;
  border: 2px solid #000;
  overflow: hidden; }
  @media (max-width: 499px) {
    .overlay-layout__inner {
      max-height: 100vh; } }

.overlay-layout__content {
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative; }

::-webkit-scrollbar {
  width: 4px; }

::-webkit-scrollbar-thumb {
  border-radius: 0;
  width: 100%;
  background-color: #666699; }

.overlay-layout--is-open .overlay-layout__content {
  pointer-events: all; }

.archive--col-0 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 50%; }
  @media (max-width: 767px) {
    .archive--col-0 {
      max-width: 90%; } }

@media (max-width: 767px) {
  .archive--col-0.archive-header-button {
    max-width: 100%;
    justify-content: space-between; } }

.archive--col-1,
.archive--col-1.archive-header-button {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 25%; }
  @media (max-width: 767px) {
    .archive--col-1,
    .archive--col-1.archive-header-button {
      display: none; } }

.archive--col-2 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 25%;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .archive--col-2 {
      max-width: 10%;
      justify-content: flex-end; } }

@media (max-width: 767px) {
  .archive-header-button.archive--col-2 {
    display: none; } }

@media (max-width: 767px) {
  .archive--col-2 p {
    display: none; } }

@media (max-width: 767px) {
  .archive--col-2 .archive-header-button {
    display: none; } }

@media (max-width: 767px) {
  .archive--col-2 p {
    display: none; } }

.core-button {
  position: relative;
  cursor: pointer;
  padding: 0 0.6rem;
  height: 1.8rem;
  vertical-align: middle;
  border: 2px solid #000;
  background: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.25rem;
  line-height: 1em;
  text-transform: uppercase;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  font-weight: 500; }

.core-button--active,
.core-button:active {
  border: 2px solid #666699;
  background: #666699;
  color: #fff; }

.core-button--reverse-color {
  border: 2px solid #fff;
  background: #666699;
  color: #fff; }

.core-button--reverse-color.core-button--active,
.core-button--reverse-color:active {
  border: 2px solid #fff;
  background: #fff;
  color: #666699; }

.core-button:before {
  content: '';
  width: 120%;
  height: 240%;
  background: red;
  display: block;
  position: absolute;
  top: -70%;
  left: -10%;
  opacity: 0; }

.core-button-unstyled,
.core-button.core-button-unstyled,
button {
  background-color: transparent;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  text-transform: uppercase; }

.header {
  width: 100%;
  padding: 15px;
  display: flex;
  position: fixed;
  height: 60px;
  z-index: 999;
  top: 0;
  left: 0; }
  @media (max-width: 499px) {
    .header {
      /*padding: $padding--small;*/
      padding-bottom: 0;
      height: 40px; } }

@media (max-width: 499px) {
  .large-text .header {
    height: 50px; } }

.header__inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

@media (max-width: 499px) {
  .large-text .header__button {
    padding-left: 0.2rem;
    padding-right: 0.2rem; } }

@media (max-width: 440px) {
  .search-input {
    display: none; } }

.header__title {
  color: #666699;
  text-transform: uppercase;
  text-align: center; }
  @media (max-width: 499px) {
    .header__title {
      display: none; } }

@media (max-width: 299px) {
  .header__inner {
    height: 36px; } }

.text-size-controls {
  white-space: nowrap; }

.text-size-button {
  text-transform: none; }

.text-size-button--small {
  font-size: 0.8rem; }

.text-size-button--large {
  font-size: 1.2rem; }

.text-size-button:first-child {
  margin-right: 0.2rem; }

.header__title {
  font-size: 2rem;
  line-height: 1em;
  font-family: "AlbertusMTPro", optimo, serif;
  color: #fff; }
  @media (max-width: 767px) {
    .header__title {
      font-size: 1.2rem; } }

.header__filter-count {
  font-weight: 700;
  color: #666699; }

.archive__list {
  margin-left: 5px;
  margin-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 100px; }

.archive__no-items {
  display: block;
  margin-top: 72px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }

.about {
  margin: 8rem 0; }
  @media (max-width: 767px) {
    .about {
      margin: 2rem 0; } }
  @media (max-width: 499px) {
    .about {
      margin: 0; } }

.about__section {
  width: 90%;
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto; }

.about__section h2 {
  font-size: 1.6rem;
  line-height: 1.4em;
  font-family: "AlbertusMTPro", optimo, serif;
  color: #666699;
  margin-top: 2em; }

.about__section p {
  font-size: 1.5rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
  margin-bottom: 1em; }

.about__section li {
  font-size: 1.5rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }

.about__section ul:not(.metadata-table) {
  margin-bottom: 1em;
  margin-left: 2em;
  list-style: disc; }

.about__section:first-of-type h2:first-child {
  margin-bottom: 1em; }

.about-section__main-logo {
  display: block;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 499px) {
    .about-section__main-logo {
      margin-top: 40px;
      width: 60%; } }

.about__supporter {
  vertical-align: middle;
  margin-bottom: 1rem; }
  @media (min-width: 769px) {
    .about__supporter {
      margin-right: 2rem;
      width: calc(16% - 1.4rem); }
      .about__supporter:nth-child(6n) {
        margin-right: 0; } }
  @media (max-width: 768px) {
    .about__supporter {
      margin-right: 1rem;
      width: calc(33% - 0.7rem); }
      .about__supporter:nth-child(3n) {
        margin-right: 0; } }

.archive-header {
  display: flex;
  position: absolute;
  top: 0;
  background-color: white;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 998;
  border-bottom: 2px solid #000; }

.archive-header-button {
  font-size: 1.15rem;
  line-height: 1.125em;
  font-family: "AlbertusMTPro", optimo, serif;
  color: #666699;
  text-transform: uppercase;
  vertical-align: center;
  height: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative; }
  .archive-header-button:before {
    content: '';
    width: 300%;
    height: 150%;
    background: red;
    position: absolute;
    opacity: 0; }

.archive-header-button--is-active {
  font-weight: bold; }

.archive-header-button__direction-icon {
  border: 2px solid #000;
  width: 1rem;
  height: 1rem; }

.archive-header-button__direction-icon {
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  border: 2px solid #000;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 0.5em;
  vertical-align: center; }

.archive-header-button__direction-icon svg {
  transform-origin: 50% 50%;
  width: 50%; }

.archive-header-button__direction-icon svg * {
  fill: #000; }

.archive-header-button__direction-icon--down svg {
  transform: rotate(180deg); }

.archive-item {
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc(100% - (5px * 2));
  border-bottom: 1px solid #000; }

.archive-item:last-child {
  border-bottom: none; }

.archive-item__header {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }

.archive-item__header-title-container {
  border: 1px solid transparent;
  margin-right: 15px;
  overflow: hidden;
  cursor: pointer; }

.archive-item__play-pause-icon {
  width: 1.1rem;
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease; }

.archive-item__play-pause-icon svg {
  vertical-align: -2px; }

.archive-item__play-pause-icon svg * {
  fill: #666699; }

.archive-item--is-playing .archive-item__play-pause-icon {
  opacity: 1; }

@media (hover: hover) {
  .archive-item__header-title-container--audio-button:hover .archive-item__play-pause-icon {
    opacity: 1; }
  .archive-item__header-title-container--audio-button:hover .archive-item__header-title {
    transform: translateX(1.3rem); } }

@media (hover: none) {
  .archive-item__header-title-container--audio-button .archive-item__play-pause-icon {
    opacity: 1; }
  .archive-item__header-title-container--audio-button .archive-item__header-title {
    transform: translateX(1.3rem); } }

.archive-item__header-title {
  transition: transform 0.2s ease;
  text-overflow: ellipsis;
  overflow: hidden; }

.archive-item--is-playing .archive-item__header-title {
  transform: translateX(1.3rem);
  font-weight: 700; }

.archive-item__main {
  padding: 2.5rem 0;
  display: none; }

.archive-item__main--expanded {
  display: block; }

.archive-item__image-wrapper {
  width: 90%;
  max-width: 55rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: block;
  position: relative; }

.archive-item__main-img {
  width: 100%; }

.archive-item__image-link {
  margin-top: 0.5em;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }

.archive-item__text-content {
  width: 90%;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto; }

.archive-item__description-intro {
  font-size: 1.6rem;
  line-height: 1.4em;
  font-family: "AlbertusMTPro", optimo, serif;
  color: #666699;
  margin-bottom: 1em; }

.archive-item__description-main p {
  font-size: 1.5rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
  margin-bottom: 1em; }

.archive-item__expand-button {
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  border: 2px solid #000;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  position: relative; }
  .archive-item__expand-button:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -75%;
    width: 250%;
    height: 200%;
    background: red;
    opacity: 0; }

.archive-item__expand-button > span {
  vertical-align: 1px;
  display: inline-block;
  line-height: 1em; }

.archive-audio-player__wrapper {
  height: calc(100% - 15px);
  position: relative;
  pointer-events: none; }

.archive-audio-player {
  height: auto;
  width: 100%;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
  bottom: 0;
  left: 0;
  padding: 15px;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%); }
  @media (max-width: 499px) {
    .archive-audio-player {
      padding: 0; } }

.archive-audio-player.archive-audio-player--is-visible {
  transform: translateY(0); }

.archive-audio-player__inner {
  position: relative;
  width: 100%;
  height: 70px;
  background: #666699;
  pointer-events: all;
  justify-content: space-between; }

.archive-audio-player__info-container {
  width: calc(100% - (50px + (10px * 3)));
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  left: calc(50px + (10px * 2));
  right: 10px;
  top: 10px; }

.archive-audio-player__title {
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }
  @media (max-width: 499px) {
    .archive-audio-player__title {
      display: none; } }

.archive-audio-player__play-pause-button {
  width: 50px;
  height: 50px;
  margin: 10px; }

.transcript {
  margin: 8rem 0; }

.transcript p {
  font-size: 1.5rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
  width: 90%;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em; }

.transcript em {
  font-style: italic; }

.transcript strong {
  font-family: "AlbertusMTPro", optimo, serif;
  color: #666699;
  text-transform: uppercase; }

.search-input {
  position: relative;
  cursor: pointer;
  padding: 0 0.6rem;
  height: 1.8rem;
  vertical-align: middle;
  border: 2px solid #000;
  background: #fff;
  font-size: 1.25rem;
  line-height: 1em;
  text-transform: uppercase;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  font-weight: 500;
  line-height: 1.8rem;
  width: 30vw;
  margin-right: 0.3rem; }

input[type=text] {
  -webkit-appearance: none;
  border-radius: 0; }

.search-input::-webkit-input-placeholder {
  color: #666699; }

.search-input:-ms-input-placeholder {
  color: #666699; }

.search-input::-ms-input-placeholder {
  color: #666699; }

.search-input::placeholder {
  color: #666699; }

.search-input:focus {
  outline: none; }

.player-controls {
  height: auto;
  width: 100%;
  overflow: hidden;
  position: fixed;
  pointer-events: none;
  bottom: 0;
  left: 0;
  padding: 15px; }
  @media (max-width: 499px) {
    .player-controls {
      padding: 0; } }

.player-controls--is-hidden {
  pointer-events: none;
  opacity: 0; }

.player-controls__inner {
  position: relative;
  width: 100%;
  height: 70px;
  background: #666699;
  pointer-events: all;
  justify-content: space-between; }

.button-holder--play-pause {
  width: auto;
  display: inline-block; }

.player-controls__info-container {
  width: calc(100% - (50px + (10px * 3)));
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  left: calc(50px + (10px * 2));
  right: 10px;
  top: 10px; }

@media (max-width: 767px) {
  .player-controls__info-container--additional-controls-visible-on-mobile {
    width: calc(100% - (10px * 2));
    left: 10px;
    flex-wrap: wrap-reverse; } }
  @media (max-width: 767px) and (min-width: 340px) {
    .player-controls__info-container--additional-controls-visible-on-mobile {
      justify-content: flex-end; } }

.player-controls__toggle-container .core-button {
  margin-left: 0.3rem; }

.player-controls__play-pause-button {
  width: 50px;
  height: 50px;
  margin: 10px; }

.player-controls__toggle-additional-controls {
  width: 2em;
  margin-bottom: 0.2em; }
  @media (min-width: 768px) {
    .player-controls__toggle-additional-controls {
      display: none; } }

.player-controls__additional-control {
  margin-right: 0.2em;
  margin-bottom: 0.2em; }

@media (max-width: 767px) {
  .player-controls__additional-control--hidden-on-mobile {
    display: none; } }

.player-controls__title {
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  margin-top: -0.13em;
  font-size: 1.5rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }
  @media (max-width: 319px) {
    .player-controls__title {
      display: none; } }

@media (max-width: 499px) {
  .large-text .player-controls__title {
    display: none; } }

@media (max-width: 767px) {
  .player-controls__title--hidden-on-mobile {
    display: none; } }

.chapter-markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.chapter-markers__marker {
  width: 4px;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  opacity: 0.5; }

.progress-bar__outer:focus .chapter-markers__marker {
  opacity: 1; }

@media (max-width: 767px) {
  .hide-below-768 {
    display: none; } }

.chapter-markers__marker:nth-child(1) {
  left: 6.9%; }

.chapter-markers__marker:nth-child(2) {
  left: 12.6%; }

.chapter-markers__marker:nth-child(3) {
  left: 17.8%; }

.chapter-markers__marker:nth-child(4) {
  left: 27.5%; }

.chapter-markers__marker:nth-child(5) {
  left: 32%; }

.chapter-markers__marker:nth-child(6) {
  left: 40.2%; }

.chapter-markers__marker:nth-child(7) {
  left: 48.1%; }

.chapter-markers__marker:nth-child(8) {
  left: 51.45%; }

.chapter-markers__marker:nth-child(9) {
  left: 59.7%; }

.chapter-markers__marker:nth-child(10) {
  left: 62.4%; }

.chapter-markers__marker:nth-child(11) {
  left: 75.4%; }

.chapter-markers__marker:nth-child(12) {
  left: 85.4%; }

.play-pause-button {
  border-radius: 50%;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  cursor: pointer;
  background: #fff;
  border: 2px solid #fff;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .play-pause-button:before {
    content: '';
    width: 200%;
    height: 200%;
    background: red;
    position: absolute;
    opacity: 0; }

.play-pause-button:active {
  background: #666699;
  border-color: #fff; }

.play-pause-button svg {
  width: 40%;
  height: auto; }

.play-pause-button--is-paused svg {
  margin-left: 7%; }

.play-pause-button svg * {
  fill: #666699; }

.play-pause-button:active svg * {
  fill: #fff; }

@media (max-width: 767px) {
  .player-controls__play-pause-button--hidden-on-mobile {
    display: none; } }

.progress-bar {
  display: flex;
  align-items: center;
  flex-grow: 1;
  vertical-align: middle;
  position: absolute;
  height: 4px;
  pointer-events: auto;
  margin-right: 10px;
  left: calc(50px + (10px * 2));
  width: calc(100% - (50px + (10px * 3)));
  bottom: 12px; }

@media (max-width: 767px) {
  .player-controls--secondary-options-visible .progress-bar {
    display: none; } }

.progress-bar__outer {
  display: inline-block;
  flex-grow: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  vertical-align: middle;
  position: relative;
  cursor: pointer; }
  .progress-bar__outer:before, .progress-bar__outer:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 20px;
    content: ""; }
  .progress-bar__outer:before {
    top: -20px; }
  .progress-bar__outer:after {
    bottom: -20px; }

.progress-bar__outer--grabbing {
  cursor: -webkit-grabbing; }

.progress-bar__inner {
  display: inline-block;
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  transition: background 0.33s ease;
  pointer-events: none;
  -webkit-user-select: none;
  max-width: 100%; }

.progress-bar__inner--is-hidden {
  background: transparent; }

.progress-bar__tracking-highlight {
  display: inline-block;
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  -webkit-user-select: none;
  background: transparent;
  transition: background 0.33s ease; }

.progress-bar__tracking-highlight--is-visible {
  background: white; }

.progress-bar__cursor {
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 0;
  margin-top: 0;
  margin-left: 0;
  background: white;
  pointer-events: none;
  opacity: 1; }

.progress-bar__cursor--is-visible {
  top: 50%;
  width: 4px;
  height: 25px;
  margin-top: -16px; }

.progress-bar__time {
  color: #fff;
  margin-left: 1rem;
  min-width: 10rem;
  text-align: right;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500; }
  @media (max-width: 499px) {
    .progress-bar__time {
      display: none; } }

.video-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0c0c0c;
  background: #111;
  pointer-events: none; }

.video-player__video {
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: hidden;
  will-change: visibility; }

.video-player__video--current {
  visibility: visible; }

.video-player__play-pause-button {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin-top: -60px;
  margin-left: -60px;
  border: 4px solid #666699; }

.video-player__play-pause-button--hidden {
  opacity: 0; }

.metadata-table {
  margin: 4rem 0; }

.metadata-table__row:not(:last-child) {
  margin-bottom: 2rem; }
  @media (min-width: 500px) {
    .metadata-table__row:not(:last-child) {
      margin-bottom: 0.625rem; } }

.metadata-table__label {
  font-size: 1.15rem;
  line-height: 1.125em;
  font-family: "AlbertusMTPro", optimo, serif;
  color: #666699;
  text-transform: uppercase;
  display: inline-block;
  padding-right: 1em;
  vertical-align: middle;
  width: 100%; }
  @media (min-width: 500px) {
    .metadata-table__label {
      width: 40%; } }

.metadata-table__value {
  font-size: 1.25rem;
  line-height: 1.5em;
  font-family: "Work Sans", helvetica, arial, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  width: 100%; }
  @media (min-width: 500px) {
    .metadata-table__value {
      width: 60%; } }

@media (min-width: 500px) {
  .metadata-table__row--mobile-only {
    display: none; } }

.archive-filters {
  width: 90%;
  max-width: 40rem;
  margin: 8rem 0;
  margin-left: auto;
  margin-right: auto; }

.archive-filters__section {
  margin-bottom: 2.5rem; }

.archive-filters__subtitle {
  font-size: 1.15rem;
  line-height: 1.125em;
  font-family: "AlbertusMTPro", optimo, serif;
  color: #666699;
  text-transform: uppercase;
  margin-bottom: 1em; }

.archive-filters__toggle {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  height: auto;
  min-height: 1.8em; }

/**
 * @license
 * MyFonts Webfont Build ID 3810941, 2019-09-24T10:59:03-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AlbertusMTPro by Monotype
 * URL: https://www.myfonts.com/fonts/mti/albertus/pro-roman/
 * Copyright: Font software (c) 1992 Adobe Systems Incorporated. Typeface designs (c) 1992 The Monotype Corporation. All rights reserved.
 * Licensed pageviews: 250,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3810941
 * 
 * © 2019 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'AlbertusMTPro';
  src: url("/webfonts/3A267D_0_0.eot");
  src: url("/webfonts/3A267D_0_0.eot?#iefix") format("embedded-opentype"), url("/webfonts/3A267D_0_0.woff2") format("woff2"), url("/webfonts/3A267D_0_0.woff") format("woff"), url("/webfonts/3A267D_0_0.ttf") format("truetype"); }

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  color: #000;
  font-size: 16px; }

html.large-text {
  font-size: 20px; }

* {
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none; }

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none; } }

.img-responsive {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; }

a {
  color: inherit; }

button:focus,
[role="button"]:focus,
a:focus,
input[type=text]:focus,
[role=progressbar]:focus {
  outline: 2px dashed #99cccc; }


.core-button {
  position: relative;
  cursor: pointer;
  padding: 0 0.6rem;
  height: 1.8rem;
  vertical-align: middle;
  border: 2px solid #000;
  background: #fff;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  
  @include typo--button;
}

.core-button--active,
.core-button:active {
  border: 2px solid $color--primary;
  background: $color--primary;
  color: #fff;
}

.core-button--reverse-color {
  border: 2px solid #fff;
  background: $color--primary;
  color: #fff;
}

.core-button--reverse-color.core-button--active,
.core-button--reverse-color:active {
  border: 2px solid #fff;
  background: #fff;
  color: $color--primary;
}

// Make hit areas LARGER!!
.core-button:before {
  content: '';
  width: 120%;
  height: 240%;
  background: red;
  display: block;
  position: absolute;
  top: -70%;
  left: -10%;
  opacity: 0;
}
.search-input {
	position: relative;
	cursor: pointer;
	padding: 0 0.6rem;
	height: 1.8rem;
	vertical-align: middle;
	border: 2px solid #000;
	background: #fff;
	@include typo--button;
	line-height: 1.8rem;
	width: 30vw;
	margin-right: 0.3rem;
}

input[type=text] {
	-webkit-appearance: none;
	border-radius: 0;
}

.search-input::placeholder {
	color: $color--primary;
}

.search-input:focus {
	outline: none;
}

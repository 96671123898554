.progress-bar {
	display: flex;
	align-items: center;
	flex-grow: 1;
	vertical-align: middle;
	position: absolute;
	height: 4px;
	pointer-events: auto;
	margin-right: 10px;
	left: calc(#{$diameter--button--play-pause__large} + (#{$padding--med} * 2));
	width: calc(100% - (#{$diameter--button--play-pause__large} + (#{$padding--med} * 3)));
	bottom: 12px;
}

.player-controls--secondary-options-visible .progress-bar {
	@media(max-width: 767px) {
		display: none;
	}
}

.progress-bar__outer {
	display: inline-block;
	flex-grow: 1;
	height: 4px;
	background: rgba(255,255,255,0.3);
	vertical-align: middle;
	position: relative;
	cursor: pointer;

	&:before, &:after {
		position: absolute;
		left: 0;
		width: 100%;
		height: 20px;
		content: "";
	}

	&:before {
		top: -20px;
	}

	&:after {
		bottom: -20px;
	}
}

.progress-bar__outer--grabbing {
	cursor: -webkit-grabbing;
}

.progress-bar__inner {
	display: inline-block;
	position: absolute;
	width: 0%;
	height: 100%;
	top: 0;
	left: 0;
	background: $color--background;
	transition: background 0.33s ease;
	pointer-events: none;
	-webkit-user-select: none;
	max-width: 100%; // Sometimes when you resize, the size can momenterily go too large as it it set with JS, so make sure it doesn't overflow
}

.progress-bar__inner--is-hidden {
	background: transparent;
}

.progress-bar__tracking-highlight {
	display: inline-block;
	position: absolute;
	width: 0%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	-webkit-user-select: none;
	background: transparent;
	transition: background 0.33s ease;
}

.progress-bar__tracking-highlight--is-visible {
	background: $color--background;
}

.progress-bar__cursor {
	vertical-align: middle;
	position: absolute;
	top: 0;
	left: 0;
	width: 4px;
	height: 4px;
	border-radius: 0;
	margin-top: 0;
	margin-left: 0;
	background: $color--background;
	pointer-events: none;
	opacity: 1;
}

.progress-bar__cursor--is-visible {
	top: 50%;
	width: 4px;
	height: 25px;
	margin-top: -16px;
}

.progress-bar__time {
	color: #fff;
	margin-left: 1rem;
	min-width: 10rem;
	text-align: right;
	@include typo--body-small;
	@media(max-width: 499px) {
		display: none;
	}
}
